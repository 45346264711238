<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="6" style="padding-left: 2rem;">
          <validation-observer ref="currentPassword">
            <b-form-group
            label="Old Password"
            label-for="account-old-password"
          >
            <validation-provider
              #default="{ errors }"
              name="currentPassword"
              vid="currentPassword"
              rules="required|password"
            >
              <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
            <b-form-input
              id="account-old-password"
              v-model="passwordValueOld"
              name="old-password"
              :state="errors.length > 0 ? false:null"
              :type="passwordFieldTypeOld"
              placeholder="Enter old password"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIconOld"
                class="cursor-pointer"
                @click="togglePasswordOld"
              />
            </b-input-group-append>
          </b-input-group>
            </validation-provider>
          </b-form-group>
          </validation-observer>
        </b-col>
        <!--/ old password -->
      </b-row>
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            style="display: flex;"
          >

            <!-- password -->
            <b-col>
              <b-form-group
                label="New Password"
                label-for="account-new-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    :state="errors.length > 0 ? false:null"
                    name="new-password"
                    placeholder="Enter new password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            </b-col>

            <!-- confirm password -->
            <b-col>
              <b-form-group
                label-for="account-retype-new-password"
                label="Confirm Password"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="account-retype-new-password"
                      v-model="RetypePassword"
                      :type="passwordFieldTypeRetype"
                      :state="errors.length > 0 ? false:null"
                      name="account-retype-new-password"
                      placeholder="Retype new password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            </b-col>
          </b-form>
        </validation-observer>

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="validationForm"
          >
            Save changes
          </b-button>
        </b-col>
        <!--/ buttons -->
    </b-form>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations' 
import store from '@/store'
import ToastificationContent from "@core/components/toastification/ToastificationContent"

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const data = {
            current_password: this.passwordValueOld,
            new_password: this.RetypePassword,
          }
          store.dispatch('Profile/UPDATE_MY_PASSWORD', data)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'You have successfully changed your password!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .catch(err => {
              this.$refs.currentPassword.errors.currentPassword.push('Password is Invalid')
            })
        }
      })
    },
  },
}
</script>
