<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="formData.profile_picture"
            height="80"
            width="80"
            style="object-fit: cover"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="formData.profile_picture"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @change="inputImageRenderer"
        />
        <!--/ upload button -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 2 MB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="First name"
            label-for="first_name"
          >
            <b-form-input
              v-model="formData.first_name"
              placeholder="First name"
              name="first_name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Last Name"
            label-for="last_name"
          >
            <b-form-input
              v-model="formData.last_name"
              name="last_name"
              placeholder="Last Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Company Name"
            label-for="company_name"
          >
            <b-form-input
              v-model="formData.company_name"
              name="company_name"
              placeholder="Company Name"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="textarea"
              v-model="formData.description"
              placeholder="Description"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="sendRequestToUpdateUser"
          >
            Save changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    formData: {
      first_name: '',
      last_name: '',
      description: '',
      profile_picture: '',
      company_name: '',
    },
  }),
  computed: {
    profileData() {
      return this.$store.state.Profile.profileData
    },
  },
  watch: {
    profileData (val) {
      this.formData.first_name = val.first_name
      this.formData.last_name = val.last_name
      this.formData.description = val.description
      this.formData.profile_picture = val.profile_picture
      this.formData.company_name = val.company_name
    }
  },
  mounted() {
    setTimeout(() => {
      this.formData.first_name = this.profileData.first_name
      this.formData.last_name = this.profileData.last_name
      this.formData.description = this.profileData.description
      this.formData.profile_picture = this.profileData.profile_picture
      this.formData.company_name = this.profileData.company_name
    }, 500)
  },
  methods: {
    inputImageRenderer(e) {
      const file = e.target.files[0]
      this.getBase64(file)
    },
    getBase64(file) {
      const reader = new FileReader()
      reader.onload = e => {
        this.formData.profile_picture = e.target.result
      }
      reader.readAsDataURL(file)
    },
    sendRequestToUpdateUser() {
      this.$store.dispatch('Profile/UPDATE_MY_PROFILE', {
        first_name: this.formData.first_name,
        last_name: this.formData.last_name,
        description: this.formData.description,
        profile_picture: this.formData.profile_picture,
        company_name: this.formData.company_name,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'You have successfully updated your profile!',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.detail ? err.response.data?.detail : 'Please try again'
            },
          })
        })
    },
  },
}
</script>
