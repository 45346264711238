<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="6" style="padding-left: 2rem;">
          <label>Resend Activation</label>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-col cols="12">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="resetEmail"
        >
          Resend
        </b-button>
      </b-col>
    </b-form>
  </b-card>
</template>

<script> 
import {
  BButton, BForm, BRow, BCol, BCard
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations' 
import store from '@/store'
import ToastificationContent from "@core/components/toastification/ToastificationContent"

export default {
  components: {
    BButton,
    BForm,  
    BRow,
    BCol,
    BCard,    
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    resetEmail () {
      this.$store.dispatch('Profile/RESET_EMAIL')
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const data = {
            current_password: this.passwordValueOld,
            new_password: this.RetypePassword,
          }
          store.dispatch('Profile/UPDATE_MY_PASSWORD', data)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'You have successfully changed your password!',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(err => {
                this.$refs.currentPassword.errors.currentPassword.push('Password is Invalid')
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Something went wrong',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: err.response.data?.detail ? err.response.data?.detail : 'Please try again'
                  },
                })
              })
        }
      })
    },
  },
}
</script>
